<template>
    <login-layout>
        <div class="ce-login-card">
            <h3 class="ce-login-card-title">login</h3>
            <form @submit.prevent="login(credentials)">
                <div class="ce-login-card-item-wrap">
                    <div class="ce-login-card-item-wrap-content">
                        <h3 class="ce-login-card-item-wrap-content-title">Email / Username
                        </h3>
                        <div class="ce-login-card-item-wrap-input">
                            <input v-model="credentials.email" type="text" class="form-control"
                                   placeholder="Enter Username">
                        </div>
                    </div>

                    <div class="ce-login-card-item-wrap-content">
                        <h3 class="ce-login-card-item-wrap-content-title">Password
                        </h3>
                        <div class="ce-login-card-item-wrap-input ce-password">
                            <input v-model="credentials.password" type="password" class="form-control"
                                   placeholder="Enter password">
                        </div>
                    </div>

                <div class="ce-forgot-wrap">
                    <ul>
                        <router-link active-class="active"
                                     class="nav-item" :to="{path:'/recover-email'}" tag="li" exact>
                            <a >Recover Email</a>
                        </router-link>
                        <router-link active-class="active"
                                     class="nav-item" :to="{path:'/reset-password'}" tag="li" exact>
                            <a >Forgot Password</a>
                        </router-link>
                    </ul>
                </div>
            </div>

                <div class="ce-login-btn-wrap">
                    <button class="btn ce-btn-login-submit">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </login-layout>
</template>
<script>
    import {mapActions, mapGetters} from 'vuex';
    import {ACTION_LOGIN, GETTER_IS_LOGGED_IN} from '@/store/modules/User';
    // import FingerPrint2 from 'fingerprintjs2';
    import LoginLayout from '@/layouts/LoginLayout';

    export default {
        data() {
            return {
                credentials: {
                    email: '',
                    password: '',
                    fingerprint: false,
                }
            }
        },
        components: {
            'login-layout': LoginLayout
        },
        methods: {
            ...mapActions('User', {
                login: ACTION_LOGIN,
            })
        },
        computed: {
            ...mapGetters('User', {
                is_logged_in: GETTER_IS_LOGGED_IN
            })
        },
        created() {
            if (this.is_logged_in) {
                this.$router.push('/home');
            }
        }
    }
</script>
